
<template>
  <el-card>
    <el-row style="margin: 0 0 15px">
      <el-button type="primary" @click="add">添加新闻</el-button>
    </el-row>

    <el-table :data="data" border style="width: 100%; margin: 15px 0">
      <el-table-column
        align="center"
        prop="title"
        label="标题"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="time"
        label="时间"
      ></el-table-column>
      <el-table-column align="center" prop="img" label="img">
        <template v-slot="scope">
          <el-image
            style="width: 50px; height: 50px"
            :src="scope.row.img"
            :preview-src-list="[scope.row.img]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" label="banner">
        <template v-slot="scope">
          <el-image
            style="width: 50px; height: 50px"
            :src="scope.row.banner"
            :preview-src-list="[scope.row.banner]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" label="mobileBanner">
        <template v-slot="scope">
          <el-image
            style="width: 50px; height: 50px"
            :src="scope.row.mobileBanner"
            :preview-src-list="[scope.row.mobileBanner]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" size="small" type="text"
            >编辑</el-button
          >
          <el-button
            
            @click="del(scope.row.id)"
            size="small"
            type="text"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[2, 4, 6, 8, 10]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!--    弹窗-->
    <el-dialog
      title="添加案列"
      :visible.sync="dialogFormVisible"
      :before-close="dialogClose"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="标题" prop="title" :label-width="formLabelWidth">
          <el-input v-model="form.title" />
        </el-form-item>

        <el-form-item
          label="banner"
          prop="banner"
          :label-width="formLabelWidth"
        >
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="bannerSuccess"
          >
            <img v-if="form.banner" :src="form.banner" class="banner" />
            <i v-else class="el-icon-plus img-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="mBanner" prop="mobileBanner" :label-width="formLabelWidth">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="mobileBannerSuccess"
          >
            <img v-if="form.mobileBanner" :src="form.mobileBanner" class="banner" />
            <i v-else class="el-icon-plus img-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="img" prop="img" :label-width="formLabelWidth">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="logoSuccess"
          >
            <img v-if="form.img" :src="form.img" class="banner" />
            <i v-else class="el-icon-plus img-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="时间" prop="time" :label-width="formLabelWidth">
          <el-date-picker
            v-model="form.time"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="类" prop="type" placeholder="请输入类" :label-width="formLabelWidth">
          <el-input v-model="form.type" type="number" />
        </el-form-item>
        <el-form-item label="标签"  placeholder="请输入标签" prop="label" :label-width="formLabelWidth">
          <el-input v-model="form.label" />
        </el-form-item>
        <el-form-item label="内容" prop="text" :label-width="formLabelWidth">
          <!-- tag标签 -->
          <textarea
            style="width: 100%"
            v-model="form.text"
            cols="30"
            rows="10"
            placeholder="请输入内容"
          ></textarea>
          <!-- <el-button @click="addContent">新增</el-button> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addModify">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { addModify, delNews, getNewsPage } from "@/api/sys.js";

export default {
  name: "news",
  data() {
    return {
      dialogFormVisible: false,
      formLabelWidth: "100px",
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/file/ossUpload",
      imageUrl: "",

      total: 0,
      queryInfo: {
        // query: "",
        pageNum: 1,
        pageSize: 10,
      },

      // tag标签
      inputVisible: false,
      inputValue: "",

      data: [],
      form: {
        title: "",
        banner: "",
        mobileBanner:"",
        img: "",
        time: "",
        text: "",
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        banner: [{ required: true, message: "请上传图片", trigger: "blur" }],
        img: [{ required: true, message: "请上传img", trigger: "blur" }],
        mobileBanner: [{ required: true, message: "请上传mobileBanner", trigger: "blur" }],
        time: [{ required: true, message: "请输入时间", trigger: "blur" }],
        text: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
    };
  },

  created() {
    this.pageCase();
  },

  methods: {
    addContent() {
      this.form.content.push({ title: "", txt: "" });
    },

    add() {
      this.resetForm();
      (this.form = {
        title: "",
        banner: "",
        logo: "",
        time: "",
        content: "",
      }),
        (this.dialogFormVisible = true);
    },

    edit(r) {
      let row = { ...r };
      console.log(row);
      this.resetForm();
      // if (!(row.content[2].txt instanceof Array)) {
      //   row.content[2].txt.split(",");
      //   for (let val of row.content) {
      //     val.txt = val.title == "实现功能" ? val.txt.split(",") : val.txt;
      //   }
      // }

      this.form = row;
      this.dialogFormVisible = true;
    },

    //表单重置
    resetForm() {
      this.$nextTick(() => {
        if (this.$refs["form"] !== undefined) this.$refs["form"].resetFields();
      });
    },

    // 关闭表单
    dialogClose(done) {
      this.resetForm();
      done();
    },

    async addModify() {
      const validate = await this.$refs["form"].validate();
      if (!validate) return;
      // this.form.content[2].txt = this.form.content[2].txt.join(",");
      console.log(this.form);
      const { data, code, msg } = await addModify(this.form);
      console.log(data, code, msg);

      if (code != 200) return this.$message.error(msg);
      this.$message.success(msg);
      this.dialogFormVisible = false;
      this.pageCase();
    },
    // 获取新闻信息分页
    async pageCase() {
      const { data, code, msg, total } = await getNewsPage(this.queryInfo);

      this.data = data;
      this.total = total;
    },

    async del(par) {
      par = par.split();
      const { data, code, msg } = await delNews(par);
      if (code !== 200) return this.$message(msg);
      this.$message.success(msg);
      this.pageCase();
    },

    // 图片上传成功
    bannerSuccess(file) {
      const { data } = file;
      this.form.banner = data.url;
    },
    logoSuccess(file) {
      const { data } = file;
      // this.form.img = data.url;
      this.$set(this.form,'img',data.url)
    },
    mobileBannerSuccess(file) {
      const { data } = file;
      // this.form.img = data.url;
      this.$set(this.form,'mobileBanner',data.url)
    },

    // 标签处理
    handleClose(tag) {
      this.form.content[2].txt.splice(this.form.content[2].txt.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput[0].$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.form.content[2].txt.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },

    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.pageCase();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.pageCase();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-tag {
  white-space: normal;
}
/deep/ .el-dialog__body {
  padding: 10px 50px 0 0;
}

/deep/ .el-input__inner {
  border-color: rgb(133, 133, 133);
}

// 图片上传样式
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .img-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 775px;
  height: 350px;
  line-height: 350px;
  text-align: center;
}

/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .banner {
  width: 775px;
  height: 350px;
  display: block;
}

.contentList {
  border: 1px solid #d7d5d5;
  padding: 10px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 20px 0;
    align-content: center;
    > input {
      padding-left: 10px;
      width: 200px;
      margin-bottom: 15px;
      height: 40px;
    }
    > textarea {
      padding: 10px;
    }
  }

  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
</style>
